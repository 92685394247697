import React, { useState } from "react";
import bussinessMeeting from "../assets/images/marta_y_david_stairs.png";
import MultiStepForm from "./MultiStepForm";

const Mision = () => {
  const [showForm, setShowForm] = useState(false);

  const handleStartGrowing = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };
  return (
    <div className="px-4 lg:px-12 xl:px-16 py-12 lg:py-16 xl:py-20 container mx-auto">
      <h2 className="text-5xl lg:text-6xl xl:text-7xl font-libre text-navy text-left mb-8 lg:mb-12 leading-[1.1] font-extrabold tracking-tight">
        <span className="block">NUESTRA</span>
        <span className="block">MISIÓN</span>
      </h2>
      <div className="flex flex-col lg:flex-row items-center lg:items-start gap-8 lg:gap-16">
        <div className="w-full lg:w-1/2 text-left space-y-6">
          <p className="text-xl lg:text-2xl xl:text-[1.375rem] text-navy leading-relaxed">
            Proporcionarte el conocimiento y las herramientas necesarias para
            que puedas{" "}
            <strong>
              aplicar a tu vida el conocimiento financiero de una manera
              práctica
            </strong>{" "}
            y empieces a generar ingresos pasivos rápidamente.
          </p>
          <p className="text-xl lg:text-2xl xl:text-[1.375rem] text-navy leading-relaxed">
            <strong>¿Vienes sin conocimientos previos?</strong> Queremos
            ayudarte a que empieces HOY tu transformación con esta{" "}
            <strong>Guía práctica gratuita</strong>. Que hemos diseñado para que
            tomes acción y des el primer paso.
          </p>
          <p className="text-xl lg:text-2xl xl:text-[1.375rem] text-navy leading-relaxed">
            <strong>Haz click y empieza a construir riqueza.</strong>
          </p>

          <div className="mt-8 text-center lg:text-left">
            <button
              onClick={handleStartGrowing}
              className="bg-gold text-navy font-inter font-semibold text-base lg:text-xl py-3 px-6 lg:py-4 lg:px-8 rounded-lg hover:brightness-90 transition-all"
            >
              QUIERO MI GUIA GRATIS
            </button>
          </div>
        </div>
        <div className="w-full lg:w-1/2 mt-8 lg:mt-0">
          <div className="aspect-w-16 aspect-h-9">
            <img
              src={bussinessMeeting}
              alt="Business People Discussing"
              className="w-full h-full object-cover rounded-lg shadow-lg"
            />
          </div>
        </div>
        {showForm && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="relative w-full max-w-md">
              <button
                onClick={handleCloseForm}
                className="absolute -top-10 right-0 text-white text-2xl"
              >
                ×
              </button>
              <MultiStepForm onClose={handleCloseForm} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mision;
