import React from "react";
import LandingSection from "./components/LandingSection";
import Mision from "./components/Mision";
import Footer from "./components/Footer";

function App() {
  return (
    <div>
      <LandingSection />
      <Mision />
      <Footer />
    </div>
  );
}

export default App;
