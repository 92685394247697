import React, { useState } from "react";
import MultiStepForm from "./MultiStepForm.js";
import landing_background from "../assets/images/landing_background.png";
import gold_logo from "../assets/logos/gold.png";
import landing_models from "../assets/images/landing_marta_y_david.png";

const LandingSection = () => {
  const [showForm, setShowForm] = useState(false);

  const handleStartGrowing = () => {
    setShowForm(true);
  };

  const handleCloseForm = () => {
    setShowForm(false);
  };

  return (
    <>
      <div
        className="bg-cover bg-center h-screen"
        style={{ backgroundImage: `url(${landing_background})` }}
      >
        <div className="flex flex-col md:flex-row justify-between p-8 pb-0 h-full">
          <div className="w-full md:w-1/2 flex flex-col justify-center items-center text-center space-y-6">
            <h1 className="text-5xl font-libre text-gold">
              Toma el control de tus finanzas. <br />
              Cambia tu vida.
            </h1>
            <p className="text-inter text-white font-inter text-lg md:text-xl">
              Domina los secretos de las finanzas personales y las <br />
              inversiones de la mano de los expertos más TOP.
            </p>

            <p className="text-inter text-white font-inter text-lg md:text-xl">
              Desata todo tu potencial <br /> y haz que{" "}
              <strong>tu dinero trabaje por ti</strong>.
            </p>
            <button
              onClick={handleStartGrowing}
              className="bg-gold text-navy font-inter font-semibold text-base lg:text-xl py-3 px-6 lg:py-4 lg:px-8 rounded-lg hover:brightness-90 transition-all"
            >
              EMPIEZA A CRECER HOY
            </button>
          </div>
          <div className="w-full md:w-1/2 flex flex-col items-center justify-between">
            <img src={gold_logo} alt="Logo" className="mb-0 hidden md:block" />
            <img
              src={landing_models}
              alt="Modelos"
              className="max-w-full h-auto"
              style={{ objectFit: "cover", height: "80%" }}
            />
          </div>
        </div>
      </div>

      {showForm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
          <div className="relative w-full max-w-md">
            <button
              onClick={handleCloseForm}
              className="absolute -top-10 right-0 text-white text-2xl"
            >
              ×
            </button>
            <MultiStepForm onClose={handleCloseForm} />
          </div>
        </div>
      )}
    </>
  );
};

export default LandingSection;
