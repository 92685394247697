import React from "react";
import {
  MapPin,
  Phone,
  Mail,
  Facebook,
  Instagram,
  Linkedin,
} from "lucide-react";
import Logo from "../assets/logos/gold.png";

const Footer = () => {
  return (
    <footer className="bg-cream py-12 px-4 lg:px-12">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 gap-8">
        {/* First Column: Links and Sections */}
        <div className="space-y-8">
          {/* Contact Us Section */}
          <div>
            <h3 className="text-2xl text-navy mb-4 font-libre font-medium tracking-wide">
              Contact Us:
            </h3>
            <div className="space-y-3">
              <div className="flex items-center gap-3">
                <MapPin className="text-beige" size={24} />
                <p className="text-navy">
                  Avinguda del Carrilet 3, Building D, 2nd Floor, 08902
                  L'Hospitalet de Llobregat, Barcelona, Spain
                </p>
              </div>
              <div className="flex items-center gap-3">
                <Phone className="text-beige" size={24} />
                <p className="text-navy">629 24 42 53</p>
              </div>
              <div className="flex items-center gap-3">
                <Mail className="text-beige" size={24} />
                <p className="text-navy">marta@investingafterwork.com</p>
              </div>
            </div>
          </div>

          {/* Office Hours Section */}
          <div>
            <h3 className="text-2xl text-navy mb-4 font-libre font-medium tracking-wide">
              Office Hours:
            </h3>
            <p className="text-beige">Monday to Friday</p>
            <p className="text-navy">8:30 am - 6:30 pm</p>
          </div>

          {/* Social Media Section */}
          <div>
            <h3 className="text-2xl text-navy mb-4 font-libre font-medium tracking-wide">
              Follow us online:
            </h3>
            <div className="flex items-center gap-4">
              <a
                href="https://www.facebook.com/profile.php?id=61569661388857"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Facebook
                  className="text-beige hover:text-navy transition-colors"
                  size={32}
                />
              </a>
              <a
                href="https://www.instagram.com/investingafterworks/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Instagram
                  className="text-beige hover:text-navy transition-colors"
                  size={32}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/investing-afterworks/posts/?feedView=all"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Linkedin
                  className="text-beige hover:text-navy transition-colors"
                  size={32}
                />
              </a>
            </div>
            <p className="text-navy mt-4 text-sm">
              This is an accessible workplace. Persons with disabilities are
              welcome to apply.
            </p>
          </div>
        </div>

        {/* Second Column: Logo */}
        <div className="hidden md:block text-right">
          <img
            src={Logo}
            alt="Baboon Technologies Logo"
            className="ml-auto max-h-24 w-auto"
          />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
