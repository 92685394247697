import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { X, AlertCircle } from "lucide-react";

const MultiStepForm = ({ onClose }) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    whatsapp: "",
    jobStatus: "",
    sector: "",
    jobDescription: "",
  });
  const [errors, setErrors] = useState({});
  const [showBonusModal, setShowBonusModal] = useState(false);

  // Validation function with detailed error handling
  const validateCurrentStep = () => {
    const newErrors = {};

    switch (step) {
      case 2: // Personal Details Validation
        if (!formData.firstName.trim())
          newErrors.firstName = "Nombre es requerido";
        else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(formData.firstName))
          newErrors.firstName = "Nombre solo puede contener letras";

        // Surname validation
        if (!formData.lastName.trim())
          newErrors.lastName = "Apellido es requerido";
        else if (!/^[a-zA-ZáéíóúÁÉÍÓÚñÑ\s]+$/.test(formData.lastName))
          newErrors.lastName = "Apellido solo puede contener letras";

        // Email validation
        if (!formData.email.trim())
          newErrors.email = "Correo electrónico es requerido";
        else if (!/\S+@\S+\.\S+/.test(formData.email))
          newErrors.email = "Correo electrónico no es válido";

        // WhatsApp validation (phone number)
        if (!formData.whatsapp.trim())
          newErrors.whatsapp = "Número de WhatsApp es requerido";
        else if (!/^\+?([0-9]{2})?[-. ]?([0-9]{9,14})$/.test(formData.whatsapp))
          newErrors.whatsapp = "Número de WhatsApp no es válido";

        break;

      case 3: // Professional Details Validation
        if (!formData.jobStatus.trim())
          newErrors.jobStatus = "Situación laboral es requerida";
        break;

      default:
        break;
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Next step handler with validation
  const nextStep = () => {
    if (validateCurrentStep()) {
      setStep(step + 1);
    }
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear specific error when user starts typing
    if (errors[name]) {
      setErrors((prev) => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    }
  };

  const downloadPDF = async (
    fileName,
    downloadName = fileName,
    displayBonusModal = false
  ) => {
    try {
      const response = await fetch(`../assets/files/${fileName}`);
      const blob = await response.blob();
      const pdfBlob = new Blob([blob], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(pdfBlob);
      link.download = downloadName;

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      if (displayBonusModal) {
        setTimeout(() => {
          setShowBonusModal(true);
        }, 1000);
      }
    } catch (error) {
      console.error("Error al descargar el PDF:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //try {
    // Send form data to backend
    // const response = await fetch("http://localhost:3006/submit-form", {
    //  method: "POST",
    //  headers: {
    //    "Content-Type": "application/json",
    // },
    //  body: JSON.stringify(formData),
    // });

    //if (!response.ok) {
    //  throw new Error("Form submission failed");
    //}

    // Download PDF after successful submission
    downloadPDF(
      "iaw_guide.pdf",
      "Guía Básica sobre Cómo Invertir Bien - Investing Afterworks",
      true
    );
    //} catch (error) {
    //console.error("Error submitting form:", error);
    // Optionally show an error message to the user
    //alert(
    //  "Hubo un problema al enviar el formulario. Por favor, intenta de nuevo."
    //  );
    //}
  };

  const handleBonusDownload = () => {
    downloadPDF(
      "iaw_conceptos_basicos.pdf",
      "Conceptos básicos para dejar de ser dummie - Investing Afterworks"
    );
    setShowBonusModal(false);
  };

  const BonusModal = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
      <motion.div
        initial={{ opacity: 0, scale: 0.9 }}
        animate={{ opacity: 1, scale: 1 }}
        className="w-full max-w-4xl bg-white rounded-2xl shadow-2xl p-12 relative border border-nude text-center space-y-6"
      >
        <button
          onClick={() => setShowBonusModal(false)}
          className="absolute -top-2 -right-2 bg-nude rounded-full p-2 hover:bg-gold transition-colors"
        >
          <X className="text-navy" size={24} />
        </button>

        <motion.h2
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          className="text-4xl font-bold text-navy font-libre"
        >
          ¡Gracias por descargarte nuestra guía!
        </motion.h2>

        <p className="text-xl text-dark-beige font-inter">
          Ya has dado el primer paso para mejorar tu situación financiera y
          aumentar tu riqueza. Para que puedas sacarle el máximo partido a tu
          guía, te regalamos "Conceptos básicos para dejar de ser dummie" como
          bonus para que entiendas de una manera muy sencilla los términos
          básicos.
        </p>

        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleBonusDownload}
          className="bg-gold text-navy px-8 py-3 rounded-full shadow-lg hover:brightness-90 font-inter font-semibold transition-all"
        >
          ¡Consigue tu BONUS!
        </motion.button>
      </motion.div>
    </div>
  );

  if (showBonusModal) {
    return <BonusModal />;
  }

  // Render input with error handling
  const renderInput = (name, type, placeholder, required = true) => (
    <div className="relative mb-4">
      <input
        type={type}
        name={name}
        value={formData[name]}
        onChange={handleChange}
        placeholder={`${placeholder}${required ? " *" : ""}`}
        className={`
          w-full p-3 rounded-lg transition-all duration-300 font-inter
          bg-cream text-navy border
          ${
            errors[name]
              ? "border-red-500 bg-red-50"
              : "border-nude focus:border-gold focus:ring-2 focus:ring-gold/30"
          }
        `}
        required={required}
      />
      {errors[name] && (
        <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500">
          <AlertCircle size={20} />
        </div>
      )}
      {errors[name] && (
        <p className="text-red-500 text-sm mt-1 animate-bounce">
          {errors[name]}
        </p>
      )}
    </div>
  );

  // Render step with framer-motion animations
  const renderStep = () => {
    return (
      <AnimatePresence mode="wait">
        <motion.div
          key={step}
          initial={{ opacity: 0, x: 300 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -300 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
          className="space-y-6 relative"
        >
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute -top-2 -right-2 bg-nude rounded-full p-2 hover:bg-gold transition-colors"
          >
            <X className="text-navy" size={24} />
          </button>

          {step === 1 && (
            <div className="text-center space-y-6">
              <motion.h2
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="text-4xl font-bold text-navy font-libre"
              >
                ¡Bienvenid@ a Investing Afterworks!
              </motion.h2>
              <p className="text-xl text-dark-beige font-inter">
                Regístrate y comienza tu transformación financiera HOY. Consigue
                de regalo ésta guía que nuestros expertos han diseñado para que
                puedas aprender paso a paso cómo empezar a invertir.
                ¿Comenzamos? 🚀
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={nextStep}
                className="bg-gold text-navy px-8 py-3 rounded-full shadow-lg hover:brightness-90 font-inter font-semibold transition-all"
              >
                Comenzar
              </motion.button>
            </div>
          )}

          {/* Rest of the steps remain the same as in the previous version */}
          {step === 2 && (
            <div>
              <h3 className="text-2xl font-semibold text-dark-beige font-libre mb-6">
                Datos Personales
              </h3>
              {renderInput("firstName", "text", "Nombre")}
              {renderInput("lastName", "text", "Apellidos")}
              {renderInput("email", "email", "Email")}
              {renderInput("whatsapp", "tel", "WhatsApp")}

              <div className="flex justify-between mt-6">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={() => setStep(step - 1)}
                  className="bg-nude text-navy px-6 py-2 rounded-lg font-inter"
                >
                  Atrás
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={nextStep}
                  className="bg-gold text-navy px-6 py-2 rounded-lg font-inter font-semibold"
                >
                  Siguiente
                </motion.button>
              </div>
            </div>
          )}

          {step === 3 && (
            <div>
              <h3 className="text-2xl font-semibold text-dark-beige font-libre mb-6">
                Datos Profesionales
              </h3>
              <div className="relative mb-4">
                <select
                  name="jobStatus"
                  value={formData.jobStatus}
                  onChange={handleChange}
                  className={`
                    w-full p-3 rounded-lg transition-all duration-300 font-inter
                    bg-cream text-navy border
                    ${
                      errors.jobStatus
                        ? "border-red-500 bg-red-50"
                        : "border-nude focus:border-gold"
                    }
                  `}
                  required
                >
                  <option value="">Situación Laboral *</option>
                  <option value="estudiante">Estudiante</option>
                  <option value="empleado">Empleado</option>
                  <option value="autonomo">Autónomo</option>
                  <option value="desempleado">Desempleado</option>
                </select>
                {errors.jobStatus && (
                  <div className="absolute right-3 top-1/2 transform -translate-y-1/2 text-red-500">
                    <AlertCircle size={20} />
                  </div>
                )}
                {errors.jobStatus && (
                  <p className="text-red-500 text-sm mt-1 animate-bounce">
                    {errors.jobStatus}
                  </p>
                )}
              </div>

              {renderInput("sector", "text", "Sector", false)}

              <div className="mb-4">
                <textarea
                  name="jobDescription"
                  value={formData.jobDescription}
                  onChange={handleChange}
                  placeholder="Cuéntanos sobre tu profesión (opcional)"
                  className="w-full p-3 rounded-lg border border-nude bg-cream text-navy font-inter focus:border-gold focus:ring-2 focus:ring-gold/30"
                />
              </div>

              <div className="flex justify-between mt-6">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={() => setStep(step - 1)}
                  className="bg-nude text-navy px-6 py-2 rounded-lg font-inter"
                >
                  Atrás
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  onClick={nextStep}
                  className="bg-gold text-navy px-6 py-2 rounded-lg font-inter font-semibold"
                >
                  Siguiente
                </motion.button>
              </div>
            </div>
          )}

          {step === 4 && (
            <div className="text-center space-y-6">
              <motion.h3
                initial={{ scale: 0.8, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                className="text-3xl font-bold text-navy font-libre"
              >
                ¡Felicidades!
              </motion.h3>
              <p className="text-xl text-dark-beige font-inter">
                Has completado el formulario. Tu guía de inversión te espera.
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handleSubmit}
                className="bg-gold text-navy px-8 py-3 rounded-full shadow-lg hover:brightness-90 font-inter font-semibold transition-all"
              >
                Descargar Guía
              </motion.button>
            </div>
          )}
        </motion.div>
      </AnimatePresence>
    );
  };

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/50 p-4">
      {showBonusModal ? (
        <BonusModal />
      ) : (
        <div className="w-full max-w-4xl bg-white rounded-2xl shadow-2xl p-12 relative border border-nude">
          {renderStep()}
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
